import Config from '../config';
import Lib from '../lib/lib';
import Features from 'lib/features';

const User_Identification = {
    keys_limit: 30, // max number of keys allowed in user info
    user_data: {},

    sync_pending: false,

    init: function(Feedback) {
        // validate user data
        if (!Userback.user_data) {
            return;
        }

        if (!Userback.user_data.id) {
            console.warn('User ID is required. Learn more: https://docs.userback.io/docs/user-identification');
            return;
        }

        if (typeof Userback.user_data.info !== 'object' ||
           (typeof Userback.user_data.info.email !== 'string' && typeof Userback.user_data.info.name !== 'string')) {
            console.warn('Email or Name is required. Learn more: https://docs.userback.io/docs/user-identification');
            return;
        }

        this.identify(Userback.user_data.id, Userback.user_data.info, Feedback);
    },

    /*
    {
        id: 123456,
        info: {
            email : 'someone@example.com', // reserved keyword
            name  : 'someone',             // reserved keyword
            plan  : 'something'
        }
    }
    */
    identify: function(user_id, user_info, Feedback) {
        // when the same user info has already been identified
        if (JSON.stringify(this.user_data) === JSON.stringify({id: user_id, info: user_info})) {
            return;
        }

        if (!user_id) {
            console.warn('User ID is required. Learn more: https://docs.userback.io/docs/user-identification');
            return false;
        }

        if (user_id.length > 255) {
            console.warn('User ID must be less than 255 characters. Learn more: https://docs.userback.io/docs/user-identification');
            return false;
        }

        if (typeof user_info !== 'object' ||
           (typeof user_info.email !== 'string' && typeof user_info.name !== 'string')) {
            console.warn('Email or Name is required. Learn more: https://docs.userback.io/docs/user-identification');
            return false;
        }

        if (Object.keys(user_info).length > this.keys_limit) {
            console.warn('You can only identify up to ' + this.keys_limit + ' keys. Learn more: https://docs.userback.io/docs/user-identification');
            return false;
        }

        var data_copy = {...user_info};

        Object.keys(data_copy).forEach((key) => {
            if (!this.validate(data_copy[key])) {
                delete data_copy[key];
                console.warn('Invalid "' + key + '". User value must be String, Number or Boolean');
            }
        });

        this.user_data = {
            id   : user_id,
            info : {...data_copy}
        };

        // ignore example data
        if (user_id == 123456 && user_info && user_info.email === 'someone@example.com' && user_info.name === 'someone') {
            // ignore
        } else {
            // Update widget form fields
            if (typeof user_info.email === 'string' && Lib.validateEmail(user_info.email)) {
                Feedback.email = user_info.email.trim();
            }

            if (typeof user_info.name === 'string') {
                Feedback.name = user_info.name.trim();
            }
        }

        this.sync();
    },

    // send user info to server
    sync: async function() {
        if (!Features.user_segment) {
            return;
        }

        if (!this.user_data.id) {
            return;
        }

        this.sync_pending = true;

        var form_data = new FormData();
        form_data.append('action',       'js_snippet/identify');
        form_data.append('access_token', Config.access_token);
        form_data.append('user_data',    JSON.stringify(this.user_data));

        var response = await fetch(Config.request_url + '/?identify', {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            body: form_data
        });

        response = await response.json();

        this.sync_pending = false;
    },

    // validate user info
    validate: function(value) {
        if (typeof value !== 'number' && typeof value !== 'string' && typeof value !== 'boolean') {
            return false;
        }

        return true;
    },

    // check multiple segments
    matchSegments: function(segments, match_type) {
        // this.user_data not set return false
        if (!this.user_data.id) {
            return false;
        }

        var segment_match_count = 0;
        segments.forEach((segment) => {
            if (this.matchSegment(segment)) {
                segment_match_count++;
            }
        });

        if (match_type === 'or') {
            return segment_match_count >= 1;
        } else {
            return segment_match_count === segments.length;
        }
    },

    // check a single segment
    matchSegment: function(segment) {
        var conditions = [];
        var match_type = segment.segment_type;

        try {
            conditions = JSON.parse(segment.segment_condition);
        } catch(e) {
            // JSON parse fail
        }

        var condition_match_count = 0;
        conditions.forEach((condition) => {
            var condition_match = false;

            var system_keys = {
                'identification': this.user_data.id,
                'display_name': this.user_data.info.name
            };

            var user_property = system_keys[condition.key] || this.user_data.info[condition.key];

            if (typeof user_property === 'undefined') {
                // key is not identified
            } else {
                switch (condition.operator) {
                    case 'is':
                        condition_match = user_property == condition.value;
                        break;
                    case 'not':
                        condition_match = user_property != condition.value;
                        break;
                    case 'contains':
                        condition_match = user_property.includes(condition.value);
                        break;
                    case 'contains_not':
                        condition_match = !user_property.includes(condition.value);
                        break;
                    case 'starts':
                        condition_match = user_property.startsWith(condition.value);
                        break;
                    case 'ends':
                        condition_match = user_property.endsWith(condition.value);
                        break;
                    case 'greater':
                        condition_match = user_property > condition.value;
                        break;
                    case 'less':
                        condition_match = user_property < condition.value;
                        break;
                    case 'regex':
                        condition_match = new RegExp(condition.value).test(user_property) ? true : false;
                        break;
                }
            }

            if (condition_match) {
                condition_match_count++;
            }
        });

        if (match_type === 'or') {
            return condition_match_count >= 1;
        } else {
            return condition_match_count === conditions.length;
        }
    },

    getIdentification: function() {
        return this.user_data.id;
    },

    getAll: function() {
        return this.user_data;
    }
};

export default User_Identification;
